/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "node_modules/ol/ol";

html,body{
    height:100% !important;
    width:100% !important;
}

.text-left{
    text-align: left;
}